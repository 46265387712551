<template>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light vm-navbar">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
            <!-- <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>
            </li> -->
            <!-- <li class="nav-item nav-item-text d-flex align-items-center">
                <router-link :to="{ name: 'Dashboard' }">Beranda </router-link>
            </li>
            <li class="nav-item nav-item-text d-flex align-items-center ml-1 mr-1">
                /
            </li> -->
            <li class="nav-item nav-item-text d-flex align-items-center">
                {{ title }}
            </li>
        </ul>

        <!-- SEARCH FORM -->
        <!-- <form class="form-inline ml-3">
            <div class="input-group input-group-sm">
                <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
                <div class="input-group-append">
                    <button class="btn btn-navbar" type="submit">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
            </div>
        </form> -->

        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto navbar-profile">
            <!-- Messages Dropdown Menu -->
            <!-- user menu -->
            <li class="nav-item dropdown">
                <a class="nav-link vm-user-name" data-toggle="dropdown" href="#">
                    <div class="hidden-xs vm-username">{{ user.username }}</div>
                    <div class="hidden-xs vm-role">{{ user.roles?.length > 0 ? capitalizeEachWord(user.roles[0].name) : '' }}</div>
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right  vm-dropdown-profile">
                    <div class="dropdown-item dropdown-header">
                        <img src="@assets/unknown.jpeg" class="user-image-nav img-circle" alt="User Image"
                            v-if="!user_image">
                        <img :src="user_image" class="user-image-nav img-circle" alt="User Image" v-if="user_image">
                        <h4 class="ellipsis">{{ user.full_name }}</h4>
                        <small class="ellipsis" v-if="user.company">{{ user.company.name }}</small>
                    </div>
                    <div class="dropdown-divider"></div>
                    <!-- <div class="dropdown-item">
                        <i class="fas fa-envelope mr-2"></i> 4 new messages
                        <span class="float-right text-muted text-sm">3 mins</span>
                    </div>
                    <div class="dropdown-divider"></div> -->
                    <div class="dropdown-item dropdown-footer">
                        <div class="row">
                            <!-- <router-link :to="{ name: 'profile' }"
                                class="col-md-4 btn btn-default btn-flat">Profile</router-link> -->
                            <div class="col-md-4"></div>
                            <a href="#" class="col-md-4 btn btn-default btn-flat" @click.prevent="logout">Keluar</a>
                        </div>
                    </div>
                </div>
            </li>
            <!-- ./user menu -->

            <li class="nav-item d-none">
                <a class="nav-link " data-widget="control-sidebar" data-slide="true" href="#">
                    <i class="fas fa-th-large"></i>
                </a>
            </li>
        </ul>
    </nav>
    <!-- /.navbar -->
</template>

<script>
const { console } = window
import "@assets/scss/navbar.scss"
export default {
    methods: {
        logout() {
            Api.logout()
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        user_image() {
            return this.$store.state.user_image
        },
        title() {
            let title = this.$store.state.title || 'Dashboard'
            window.document.title = [this.env.app_name, title].join(' | ')

            return title
        },
        subtitle() {
            if (this.$store.state.subtitle) {
                return this.$store.state.subtitle
            }

            return ''
        },

        capitalizeEachWord() {
            return function (name) {
                return name.toLowerCase()
                    .replace("_", " ")
                    .replace(/\b\w/g,
                        function (match) {
                            return match.toUpperCase();
                        })
            }
        }
    }
}
</script>